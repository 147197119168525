.permission-middleware__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.permission-middleware__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.lock-image {
    margin: 25px;
}

h1 {
    font-size: 15px;
}

h2 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 25px;
}